@use './styles.scss';

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-gap: 0;
  justify-content: space-around;
  align-items: start;
  margin-right: 1%;
  margin-left: 1%;
}

.hidden {
  display: none;
}

.projects_card {
  margin: 3%;
  background-color: styles.$cardColor;
  border-radius: 10px;
  padding-top: 56.25%;
  padding: 6%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  cursor: pointer;
  opacity: 1;
  transition: margin 0.15s;

  &:hover {
    margin: 2%;
  }
}

.projects_card_h2 {
  overflow: hidden;
  padding-bottom: 2%;
  // background-color: aqua;
  font-size: 1.5rem;
  line-height: 1.2;
  height: calc(1.2 * 2 * 1.5rem);
  font-weight: 800;
  color: styles.$textColor2;
}

.projects_card_image_container {
  position: relative;
  padding-top: 90%;
}

.projects_card_media {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain; // Makes the image maintain its aspect ratio while fitting within the container
  object-position: center; // Center the image within the container 
}

.projects_modal_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); // Translucent black
  z-index: 99; // Below the modal but above everything else
  animation: FadeIn 0.15s forwards;

  &.FadeOut {
    animation: FadeOut 0.15s forwards; // Animation to bubble out
  }

  @keyframes FadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.projects_modal {
  overflow: hidden;
  display: flex;  // Make this a flex container
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;       // Width of the modal
  height: 80%;      // Height of the modal
  transform: translate(-50%, -50%) scale(0.8); // Start slightly smaller for the animation
  background-color: styles.$cardColor;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  z-index: 100;
  opacity: 0; // Start invisible for the animation
  animation: bubbleIn 0.15s forwards; // Animation to bubble in

  @keyframes bubbleIn {
    0% {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  &.bubbleOut {
    animation: bubbleOut 0.15s forwards; // Animation to bubble out
  }

  // Bubble out animation
  @keyframes bubbleOut {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 0;
    }
  }
}

.projects_modal_header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  // background-color: red;
}

.projects_modal_header_h2 {
  // background-color: blue;
  color: styles.$textColor2;
  font-weight: 800;
  font-size: 2rem;
}

.projects_modal_header_button {
  border-radius: 2rem;
  width: 1.75rem; // or whatever percentage you need
  height: 1.75rem;
  min-width: 1.75rem;
  min-height: 1.75rem;
  background-color: styles.$mainColor;
  display: flex;           // Make this a flex container
  align-items: center;     // Vertically align children in the center
  justify-content: center; // Horizontally align children in the center
  position: relative;
  border: none;         // Remove border
  margin: 0;            // Remove margin
  overflow: visible;    // Show content that might be clipped
  // background: transparent; // Remove default gray background on some browsers
  line-height: normal;  // Normalize line height
  text-align: center;   // Center the text/content

  font-weight: 600;
  font-size: calc(10px + 50%);
  
  // Remove the default appearance on Webkit browsers
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  .xIcon {
    font-size: 100%;
  }

  &:hover {
    opacity: 0.8;
    // color: styles.$mainColor; // Change the icon color on hover
  }
}

.projects_modal_content {
  position: relative;
  display: flex;  // Make this a flex container
  height: 95%;
  flex-direction: row;  // This is the default value for flex-direction so it's optional
  // background-color: red;
}

.projects_modal_gallery {
  width: 50%;
  padding-top: 2%;
  padding-bottom: 2%;
  // background-color: aquamarine;
  position: relative;
  flex-direction: column;
}

.projects_modal_gallery_media_container {
  overflow: hidden;
  border-radius: 1rem;
  margin: 0% 0% 0% 0%;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: styles.$innerCardColor;
  align-items: center; // Vertically center the content
  justify-content: center; // Horizontally center the content
}

.projects_modal_gallery_media {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain; // Makes the image maintain its aspect ratio while fitting within the container
  object-position: center; // Center the image within the container 
}

.projects_modal_gallery_button_container {
  position: absolute;
  border-radius: 1rem;
  padding: 10px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: styles.$cardColor;
}

.projects_modal_gallery_button {
  display: flex;           // Make this a flex container
  align-items: center;     // Vertically align children in the center
  justify-content: center; // Horizontally align children in the center
  width: auto;
  position: relative;
  border: none;         // Remove border
  margin: 0;            // Remove margin
  padding: 0;           // Remove padding
  overflow: visible;    // Show content that might be clipped
  background: transparent; // Remove default gray background on some browsers
  line-height: normal;  // Normalize line height
  text-align: center;   // Center the text/content
            
  // Remove the default appearance on Webkit browsers
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover .projects_modal_gallery_button_icon {
    color: styles.$mainColor; // Change the icon color on hover
  }

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
}

.projects_modal_gallery_button_icon {
  position: relative;
  color: styles.$paginationColor;
  align-self: center;
  font-size: large;
}

.projects_modal_gallery_button_pagination {
  width: 10px;
  height: 10px;
  border-radius: 50%;  // Makes it a circle
  background-color: styles.$paginationColor; // Some default color
  margin: 0 5px; // Space them out a bit
  transition: background-color 0.3s; // Smooth transition

  &.active {
    background-color: styles.$mainColor; // Highlight color for the current image
  }
}

.projects_modal_content_text_container {
  width: 50%;
  padding: 0%;
  padding-top: 2%;
  // background-color: rgb(40, 101, 81);
  overflow-y: auto;
}

.projects_modal_content_subsection {
  margin: 3% 0% 3% 3%;
  border-radius: 1rem;
  background-color: styles.$innerCardColor;
  padding: 3%;

  &.top {
    margin-top: 0%;
  }
}

.projects_modal_content_h1 {
  color: styles.$textColor2;
  font-weight: 800;
  font-size: 2em;
}

.projects_modal_content_p {
  color: styles.$textColor2;
  font-weight: 400;
  font-size: 1em;
  padding: 0;
}

@media only screen and (max-width: 1400px) {
  .projects_modal {
    width: 90%;       // Width of the modal
  }
  .projects_card_h2 {
    font-size: 1rem;
    line-height: 1.2;
    height: calc(1.2 * 2 * 1rem);
  }
}

@media only screen and (max-width: 768px) {

.projects {
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
}

.projects_card_h2 {
  font-size: 0.75rem;
  line-height: 1.2;
  height: calc(1.2 * 2 * 0.75rem);
}
  
.projects_modal {
  flex-direction: column;
  width: 92%;       // Width of the modal
  height: 90%;      // Height of the modal
}
  
.projects_modal_content {
  overflow-y: auto;
  display: flex;  // Make this a flex container
  height: 95%;
  flex-direction: column;  // This is the default value for flex-direction so it's optional
  // background-color: red;
}

.projects_modal_header_h2 {
  // background-color: blue;
  font-weight: 800;
  font-size: 1.5rem;
}
  
.projects_modal_content_p_container {
  width: 100%;
  height: 50%;
  // background-color: aqua;
}
  
.projects_modal_gallery {
  width: 100%;
  // background-color: rgb(185, 108, 15);
}

.projects_modal_gallery_media_container {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

// .projects_modal_gallery_media {
//   position: relative;
//   max-height: 100%;
//   max-width: 100%;
//   object-fit: contain;
// }

.projects_modal_gallery_button_container {
  position: absolute;
  bottom: 2.5px;
  padding: 5px 5px;
}

.projects_modal_content_subsection {
  margin: 3% 0% 3% 0%;
  border-radius: 1rem;
  background-color: styles.$innerCardColor;
  padding: 3%;

  &.top {
    margin-top: 0%;
  }
}

.projects_modal_gallery_button_pagination {
  width: 7.5px;
  height: 7.5px;
}

.projects_modal_content_text_container {
  overflow-y:unset;
  width: 100%;
}

.projects_modal_content_h1 {
  font-size: 1.5rem;
}

.projects_modal_content_p {
  font-size: 0.75em;
}

}