@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

// $mainColor: #464555;
// $mainColorLight: #5767aa;
// $secondaryColor: #F17A7C;
// $cardColor: #dcdcdc;
// $innerCardColor: #ccc;
// $tertiaryColor: #ABA9BC;
// $textColor: #D8E9E8;

$mainColor: #0F172A;
$mainColorLight: #5767aa;
$secondaryColor: #70FACB;
$cardColor: #dcdcdc;
$innerCardColor: #ccc;
$paginationColor: #909090;
$tertiaryColor: #0F172A;
$textColor: #dcdcdc;
$textColor2: #0F172A;
$textColor3: #5767aa;

$navTextColor: #dcdcdc;

$iconColor: #0F172A;
$iconColorBackground: #5767aa;

$mobileHeader: 8rem;
$webHeader: 10rem;

body {
  background-color: $mainColor; /* Replace #YOUR_COLOR with your desired color */
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
}