@use './styles.scss';

.container {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  // background-color: #282c34; // Dark background color, change as needed
  color: white;

  .title {
    display: flex;
    font-weight: 900;
    font-size: 3rem;
    color: styles.$textColor;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    text-align: center;
  }

  .info {
    font-size: 1.2rem;
    margin-bottom: 1rem;

    .link {
      color: #61dafb; // Highlight color, typically used in React's default setup. Change as needed.
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .socials {
    margin-top: 1rem;
    display: flex;
    gap: 1rem; // Spacing between social icons

    .icon {
      width: 80px;
      height: 80px;

      &:hover {
        transform: scale(1.1); // Slight zoom on hover for a nice effect
      }
    }
  }
}