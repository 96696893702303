@use './styles.scss';

/* Navbar.css */
:root {
  --navbar-height: 10rem;//styles.$mobileHeader;
  --navbar-height--mobile: 8rem;//styles.$webHeader;
}

header {
  height: var(--navbar-height);
  transition: height 0.225s ease, padding 0.225s ease; // Add transition for padding
  padding-left: 1rem; // Example padding value; adjust as needed

  &.shrunkenNavbar {
    --navbar-height: 7rem; 
    padding-left: 2.2rem; // Adjust the padding value for shrunken state
  }

  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: styles.$mainColor;
  box-shadow: 0 6px 10px rgba(0,0,0,0.2);
  color: styles.$textColor;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  img {
    height: calc(var(--navbar-height) * 0.9); 
    width: auto; 
    border-radius: 50%; 
    transition: height 0.225s ease; // Add transition for image
  }
  .pageName {
    display: none;
  }
  .nav-btn {
    padding: 0.35rem;
    font-size: 2rem;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: styles.$textColor;
    display: none;
    opacity: 0;
  }
}

nav {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
  }

  li {
    font-size: 2rem;
    font-weight: 600;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 2rem; 
    color: styles.$navTextColor;
    text-decoration: none;
    border-bottom: 0.2rem solid transparent;

    &:hover {
      color: styles.$secondaryColor;
      border-bottom-color: styles.$secondaryColor;;
    }
  }
}

@media only screen and (max-width: 768px) {

  header {
    height: var(--navbar-height--mobile);
    transition: height 0.225s ease, padding 0.225s ease; // Add transition for padding
    justify-content: space-between;
  
    &.shrunkenNavbar {
      --navbar-height--mobile: 6rem; 
      padding-left: 2.2rem; // Adjust the padding value for shrunken state
    }

    .pageName {
      display: block; // This makes the element visible on mobile
      font-size: 1.5rem; // Adjust as needed
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .nav-btn {
      display: flex;
      opacity: 1;
    }

    nav {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      background-color: styles.$mainColor;
      transition: 1s;
      transform: translateY(-100vh);

      ul {
        flex-direction: column;
      }
    }

    .responsive-nav {
      transform: none;
      opacity: 1;
    }
  }
  
}