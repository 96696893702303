@use './styles.scss';

.content {
  position: relative;
  display: flex;
  margin: 0 10%;
  padding: 3% 10%;
  // background-color: aqua;
  flex-direction: row-reverse;
}

.content_p {
  margin-top: 1rem;
  color: styles.$textColor;
  display: flex;
  font-weight: 200;
  font-size: 1rem;
  opacity: 0.75;
}

.content_h1 {
  color: styles.$textColor;
  display: flex;
  font-weight: 900;
  font-size: 4rem;
}

.content_h2 {
  color: styles.$textColor;
  display: flex;
  font-weight: 400;
  font-size: 2rem;
  opacity: 0.85;
}

.content_h4 {
  color: styles.$textColor;
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  opacity: 0.85;
}

.content_h3 {
  margin-top: 6rem;
  color: styles.$secondaryColor;
  display: flex;
  font-weight: 400;
  font-size: 1.5rem;
}

.content_text_container {
  display: flex;
  flex-direction: column;
}

.content_button_container {
  display: flex;
  flex-direction: row;
  // background-color: red;
  gap: 1rem;
  Link {
    text-decoration: none;
  }
}

.content_img_container {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  // background-color: green;
}

.content_img_border {
  position: relative;
  width: 23rem;
  height: 23rem;
  border-radius: 15rem;
  border: 0.5rem solid styles.$secondaryColor;
  background-color: styles.$tertiaryColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_img {
  width: 90%;
  height: 90%;
  border-radius: 10rem;
}

.content_link {
  text-decoration: none;
}

.content_button {
  display: flex;           // Make this a flex container
  align-items: center;     // Vertically align children in the center
  justify-content: center; // Horizontally align children in the center
  position: relative;
  width:fit-content;
  border: 2px solid styles.$secondaryColor;         // Remove border
  margin-top: 1rem;            // Remove margin
  padding: 1rem 1rem;           // Remove padding
  border-radius: 0.5rem;
  overflow: visible;    // Show content that might be clipped
  background: transparent; // Remove default gray background on some browsers
  line-height: normal;  // Normalize line height
  text-align: center;   // Center the text/content

  font-weight: 200;
  font-size: 1rem;
            
  // Remove the default appearance on Webkit browsers
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: styles.$secondaryColor;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.15s ease;

  &:hover {
    transform: scale(1.05);
  }
}

@media only screen and (max-width: 1400px) {
  .content {
    position: relative;
    display: flex;
    margin: 0 10%;
    padding: 0 0%;
    // background-color: aqua;
    flex-direction: row-reverse;
  }
  .content_img_container {
    display: flex;
    align-items: flex-end;
  }
  .content_img_border {
    width: 17rem;
    height: 17rem;
    border-radius: 15rem;
    border: 0.25rem solid styles.$secondaryColor;
  }
  .content_img {
    width: 90%;
    height: 90%;
    border-radius: 10rem;
  }
  .content_h3 {
    margin-top: 4rem;
  }
  .content_h1 {
    font-size: 3rem;
  }
  .content_h2 {
    font-size: 1.5rem;
  }

  .content_h4 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    position: relative;
    display: flex;
    margin: 0 5%;
    padding: 0 0%;
    // background-color: aqua;
    flex-direction: column;
  }
  
  .content_h1 {
    color: styles.$textColor;
    display: flex;
    font-weight: 900;
    font-size: 3rem;
  }
  
  .content_h2 {
    color: styles.$textColor;
    display: flex;
    font-weight: 400;
    font-size: 1.5rem;
  }
  
  .content_h3 {
    margin-top: 1rem;
    color: styles.$secondaryColor;
    display: flex;
    font-weight: 400;
    font-size: 1rem;
  }

  .content_h4 {
    font-size: 1rem;
  }
  
  .content_img_border {
    margin-top: 1rem;
    position: relative;
    width: 12rem;
    height: 12rem;
    border-radius: 15rem;
    border: 0.25rem solid styles.$secondaryColor;
    background-color: styles.$tertiaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  
  .content_img {
    width: 90%;
    height: 90%;
    border-radius: 10rem;
  }
}